<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterRequest"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            :height="$vuetify.breakpoint.mdAndDown ? '77vh' : '74vh'"
            fixed-header
        >
            <!-- headers -->
            <template v-slot:[`header.date`]="{ header }">
                <div class="d-flex justify-center">
                    <p class="text-uppercase pt-4 mr-1">{{ header.text }}</p>
                    <v-btn
                        small
                        icon
                        v-if="activateArrow"
                        class="mt-3"
                        @click="sort('asc')"
                    >
                        <v-icon size="14">mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn small icon v-else class="mt-3" @click="sort('desc')">
                        <v-icon size="14">mdi-arrow-down</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <div class="d-flex justify-center">
                    <p class="text-uppercase pt-4 mr-1">
                        {{
                            `${header.text} ${
                                statusToFilter ? `(${statusToFilter})` : ''
                            }`
                        }}
                    </p>
                    <v-menu rounded offset-y>
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mt-3"
                            >
                                <v-icon size="14">mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click.stop="statusToFilter = 'onHold'"
                            >
                                <v-list-item-title>
                                    OnHold
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="statusToFilter = 'approved'"
                            >
                                <v-list-item-title>
                                    Approved
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.stop="statusToFilter = 'rejected'"
                            >
                                <v-list-item-title>
                                    Rejected
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <!--Items--->
            <template v-slot:[`item.date`]="{ item }">
                <p class="my-0 font-weight-black">
                    {{
                        item.updatedOn
                            ? formatDate(item.updatedOn)
                            : formatDate(item.createdOn)
                    }}
                </p>
            </template>
            <template v-slot:[`item.user`]="{ item }">
                <div class="">
                    <p class="my-0 text-capitalize">
                        {{ item.user.name }}
                    </p>
                    <p class="my-0 text-capitalize font-weight-black">
                        ({{ item.user.role }})
                    </p>
                </div>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="">
                    <p class="my-0 text-capitalize">
                        {{ item.code }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip :color="colors[item.status]" dark>
                        <span
                            class="mt-0 text-body-1 text-capitalize"
                            :style="{ 'text-align': 'center !important' }"
                            >{{
                                item.status == 'onHold'
                                    ? 'On Hold'
                                    : item.status
                            }}</span
                        >
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="approveStock"
                        small
                        icon
                        @click.stop="openEditDialog(item)"
                    >
                        <v-icon
                            v-if="
                                item.status == 'approved' ||
                                    item.status == 'rejected'
                            "
                            >mdi-eye</v-icon
                        >
                        <v-icon v-else>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="item.status == 'onHold'"
                        small
                        icon
                        @click.stop="openDeleteDialog(item)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--Edit Request-->
        <v-dialog
            :retain-focus="false"
            v-model="openRequestForm"
            persistent
            :width="
                $vuetify.breakpoint.mobile && !$vuetify.breakpoint.md
                    ? '80%'
                    : '40%'
            "
        >
            <RequestForm
                v-if="openRequestForm"
                :request="selectedRequest"
                :approveStock="approveStock"
                @updateRequest="updateRequest"
                @closeDialog="closeEditDialog"
            />
        </v-dialog>
        <!--Delete Request-->
        <v-dialog
            :retain-focus="false"
            v-model="deleteRequest"
            persistent
            :width="
                $vuetify.breakpoint.mobile && !$vuetify.breakpoint.md
                    ? '80%'
                    : '40%'
            "
        >
            <DeleteRequest
                v-if="deleteRequest"
                :request="selectedRequest"
                @deleteRequest="removeRequest"
                @closeDialog="closeDeleteDialog"
            />
        </v-dialog>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import _ from 'lodash'
import RequestForm from '@/components/StockRoom/RequestForm'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import DeleteRequest from '@/components/StockRoom/DeleteRequest'

export default {
    name: 'Request',
    props: {
        type: String,
    },
    components: {
        RequestForm,
        DeleteRequest,
    },
    data: () => ({
        statusToFilter: undefined,
        activateArrow: true,
        selectedRequest: {},
        openRequestForm: false,
        loading: false,
        openNewItem: false,
        openPurchase: false,
        openReserve: false,
        selectedItem: {},
        headers: [
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'CREATED BY',
                value: 'user',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        requests: [],
        search: '',
        colors: {
            onHold: '#EDC43B',
            approved: '#27BC4F',
            rejected: '#FF5252',
        },
        approveStock: undefined,
        deleteRequest: false,
        activateAlert: false,
        alertMessage: '',
    }),
    computed: {
        filterRequest() {
            if (this.statusToFilter) {
                return this.requests.filter(
                    r =>
                        r.status.toLowerCase() ==
                        this.statusToFilter.toLowerCase()
                )
            }
            return this.requests
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.approveStock = user.permissions.find(
                permission => permission == 'approveStock'
            )
            this.requests = await API.getRequests()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        removeRequest(id) {
            const index = this.requests.findIndex(request => request.id == id)
            if (index > -1) {
                this.requests.splice(index, 1)
            }
            this.closeDeleteDialog()
            this.alertMessage = 'The request has been successfully deleted'
            this.activateAlert = true
        },
        sort(direction) {
            if (direction == 'asc') {
                this.activateArrow = false
                this.requests.sort(
                    (a, b) =>
                        (a.updatedOn
                            ? a.updatedOn._seconds || a.updatedOn.seconds
                            : a.createdOn._seconds || a.createdOn.seconds) -
                        (b.updatedOn
                            ? b.updatedOn._seconds || b.updatedOn.seconds
                            : b.createdOn._seconds || b.createdOn.seconds)
                )
                return null
            } else if (direction == 'desc') {
                this.activateArrow = true
                this.requests.sort(
                    (a, b) =>
                        (b.updatedOn
                            ? b.updatedOn._seconds || b.updatedOn.seconds
                            : b.createdOn._seconds || b.createdOn.seconds) -
                        (a.updatedOn
                            ? a.updatedOn._seconds || a.updatedOn.seconds
                            : a.createdOn._seconds || a.createdOn.seconds)
                )
                return null
            }
        },
        updateRequest(request) {
            const index = this.requests.findIndex(r => r.id == request.id)
            if (index > -1) {
                this.requests.splice(index, 1, {
                    ...this.requests[index],
                    ...request,
                })
            }
            this.closeEditDialog()
        },
        formatDate(date) {
            return moment
                .unix(date._seconds || date.seconds)
                .format('YYYY-MM-DD')
        },
        openEditDialog(item) {
            this.openRequestForm = true
            this.selectedRequest = _.cloneDeep(item)
        },
        closeEditDialog() {
            this.openRequestForm = false
            this.selectedRequest = {}
        },
        openDeleteDialog(item) {
            this.deleteRequest = true
            this.selectedRequest = _.cloneDeep(item)
        },
        closeDeleteDialog() {
            this.deleteRequest = false
            this.selectedRequest = {}
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
    position: sticky;
    z-index: 1;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
</style>
